import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import device from "../components/device"
import Img from "gatsby-image/withIEPolyfill"
import GreenHeading from "../components/heading"
import SecondaryHeader from "../components/header/header-secondary"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { GeneralSchema } from "@ashleynexvelsolutions/general-schema"
import { PageSpecificSchema } from "@ashleynexvelsolutions/page-specific-schema"
import {
  GeneralSchemaFunction,
  PageSpecificSchemaFunction,
} from "../DataImports"

const Wrapper = styled.div`
  .secondary-header-wrapper {
    background: var(--darkgrey);
    color: var(--white);
  }

  .content {
    max-width: var(--limit);
    width: 70%;
    margin: 5rem auto 10rem auto;
    ${device.small`width: var(--spread);`}
  }

  .heading-wrapper {
    text-align: center;
    .heading {
      line-height: 1;
      font-size: 3rem;
      ${device.small`display: block; font-size: 2.5rem;`}
      .secondary-heading {
        margin-left: 3rem;
        ${device.small`margin-left: 2rem; font-size: 2rem;`}
      }
    }

    .sub-heading {
      margin: 2rem 0;
      font-size: 1.5rem;
      color: var(--grey);
      ${device.small`font-size: 1.3rem;`}
    }
  }
`

const BlogGrid = styled.section`
  margin: 4rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 2rem;
  ${device.small`
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  `}
  .grid-item {
    color: var(--black);
    text-transform: none;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.35s ease;
    will-change: box-shadow;
    &:hover {
      box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.1);
    }
    .thumbnail {
      width: 100%;
      height: 400px;
      ${device.small`height: 300px;`}
    }

    .text {
      padding: 2rem;
      ${device.small`padding: 1.5rem;`}
      p {
        margin-top: 1rem;
        font-size: 0.9rem;
      }
    }
  }
`

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  a {
    font-size: 0.9rem;
    color: var(--black);
    float: left;
    padding: 1rem 1.5rem;
    text-decoration: none;
    transition: background-color 0.3s;
    &.active {
      background: var(--green);
      color: var(--white);
    }

    &:hover:not(.active) {
      background-color: #ddd;
    }
  }
`

const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

const BlogList = ({ data, pageContext }) => {
  const posts = data.posts.edges
  const page = data.page
  const firstTitle = page.title.split(" ")[0]
  const secondTitle = page.title.replace(firstTitle, "")

  const { numPages, currentPage } = pageContext

  // const pagination = range(1, numPages, 1)
  let endPagination =
    currentPage === numPages ? numPages : 4 * (Math.floor(currentPage / 5) + 1)
  endPagination = endPagination > numPages ? numPages : endPagination
  let startPagination = endPagination - 3
  startPagination = startPagination < 1 ? 1 : startPagination
  const pagination = range(startPagination, endPagination, 1)

  return (
    <>
      <GeneralSchema {...GeneralSchemaFunction()} />
      <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />
      <Layout>
        <Helmet>
          <title>{`${data.site.siteMetadata.title} | ${data.wpgraphql.page.seo.title}`}</title>
          <meta
            name="description"
            content={data.wpgraphql.page.seo.opengraphDescription}
          />
          <meta
            property="og:url"
            content={data.wpgraphql.page.seo.opengraphUrl}
          />
          <meta
            property="og:site_name"
            content={data.wpgraphql.page.seo.opengraphSiteName}
          />
          <meta property="og:title" content={data.wpgraphql.page.seo.title} />
          <meta
            property="og:description"
            content={data.wpgraphql.page.seo.opengraphDescription}
          />
          <meta name="twitter:card" content="summary_large_image" />
          {/* Twitter */}
          <meta
            name="twitter:title"
            content={data.wpgraphql.page.seo.twitterTitle}
          />
          <meta
            name="twitter:description"
            content={data.wpgraphql.page.seo.twitterDescription}
          />

          <meta name="geo.region" content="US-PA" />
          <meta name="geo.placename" content="King of Prussia" />
          <meta name="geo.position" content="40.091710;-75.346160" />
          <meta name="ICBM" content="40.091710, -75.346160" />
        </Helmet>
        <Wrapper>
          <div className="secondary-header-wrapper">
            <SecondaryHeader />
          </div>
          <div className="content">
            <div className="heading-wrapper">
              <h1 className="heading">
                <GreenHeading size="6rem" text={firstTitle} />
                <br />
                <span className="secondary-heading">{secondTitle}</span>
              </h1>
              <h2 className="sub-heading">{page.acf.sub_heading}</h2>
            </div>

            <BlogGrid>
              {posts.map(({ node }, index) => (
                <Link to={`${node.path}`} className="grid-item" key={index}>
                  {/*<div className="thumbnail">
                    {node.featured_media && 
                     node.featured_media.localFile &&
                      <Img
                        fluid={
                          node.featured_media.localFile.childImageSharp.fluid
                        }
                        alt={node.featured_media.alt_text}
                        style={{ width: "100%", height: "100%" }}
                      />
                    }
                  </div>*/}
                  <div className="text">
                    <h3>{node.title}</h3>
                    <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                  </div>
                </Link>
              ))}
            </BlogGrid>

            <Pagination>
              <Link
                to={
                  currentPage === 1
                    ? page.path + 1
                    : page.path + (currentPage - 1)
                }
              >
                &laquo;
              </Link>
              {pagination.map((number, index) => (
                <Link
                  key={index}
                  to={page.path + number}
                  className={number === currentPage ? "active" : null}
                >
                  {number}
                </Link>
              ))}
              <Link
                to={
                  currentPage === numPages
                    ? page.path + numPages
                    : page.path + (currentPage + 1)
                }
              >
                &raquo;
              </Link>
            </Pagination>
          </div>
        </Wrapper>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    posts: allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          excerpt
          path
        }
      }
    }

    page: wordpressPage(slug: { in: "blog" }) {
      path
      title
      acf {
        sub_heading
      }
    }
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    wpgraphql {
      page(id: "4531", idType: DATABASE_ID) {
        seo {
          title
          opengraphDescription
          opengraphUrl
          opengraphSiteName
          twitterTitle
          twitterDescription
        }
      }
    }
  }
`

export default BlogList
